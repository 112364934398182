<template>
  <div style="background-color: #f0f2f5">
    <div class="central-header">
      <h1 class="titulo">
        Bem-vindo! Conheça a melhor forma de viajar pela Amazônia!
      </h1>
      <h1 class="subtitulo">Encontre aqui sua viagem de barco</h1>
    </div>
    <div class="plano-fundo"></div>
    <div class="entire-div">
      <div class="search-card">
        <div class="leftSide">
          <div>
            <label>Origem</label>
            <multiselect
              class="multiselects"
              @select="updateDestinos"
              :hideSelected="true"
              label="nome"
              v-model="origem"
              :options="viagensOrigem"
              :show-labels="false"
              :searchable="true"
              placeholder="Cidade de partida"
            >
            </multiselect>
          </div>

          <label style="margin-top: 1rem">Destino</label>
          <multiselect
            class="multiselects"
            :hideSelected="true"
            v-model="destino"
            label="nome"
            :options="viagensDestinoFiltered"
            :searchable="true"
            :show-labels="false"
            placeholder="Cidade de destino"
          >
          </multiselect>
        </div>

        <div class="rightSide">
          <div style="flex-grow: 1">
            <label>Intervalo de datas</label>
            <div id="content-datepickers">
              <div class="input-date">
                <v-date-picker
                  v-model="data_ida2"
                  :value="data_ida"
                  class="datepickers"
                  :min-date="currentDateISO"
                  :popover="{ visibility: 'click' }"
                  :input-props="{ placeholder: 'Data inicial' }"
                  locale="pt-br"
                  color="blue"
                />
              </div>
              <img :src="IconArrow" style="margin-left: 1.5rem" />
              <div class="input-date" style="padding-right: 2rem">
                <v-date-picker
                  v-model="data_volta2"
                  :value="data_volta"
                  :min-date="data_volta"
                  prepend-icon="mdi-calendar"
                  :popover="{ visibility: 'click' }"
                  :input-props="{
                    placeholder: 'Data final'
                  }"
                  locale="pt-br"
                  color="blue"
                />
              </div>
            </div>
            <label
              style="
                font-style: Roboto, sans-serif;
                font-size: 16px;
                font-style: italic;
                margin-top: 0;
                font-weight: 200;
              "
              >Informe entre quais dias você deseja iniciar sua viagem.</label
            >
          </div>
          <button class="search-button" @click="validarDados()">
            Buscar passagens
          </button>
        </div>
      </div>

      <h2 v-if="indisponivel" class="titulo-encontrados2">
        Passagens indisponíveis nessas datas
      </h2>
      <p
        v-if="indisponivel"
        class="titulo-encontrados2"
        style="font-family: 'OpenSans-Regular', sans-serif"
      >
        <a
          style="cursor: pointer; font-size: 16px; text-decoration: underline"
          @click="goTo('faleconosco')"
          >Entre em contato conosco</a
        >
      </p>
      <div v-if="!indisponivel && flagLoading" class="div-encontrados">
        <h2 class="titulo-encontrados2">
          Aqui estão os resultados da sua pesquisa!
        </h2>
        <div class="encontrado" v-for="(viagem, key) in dadosViagem" :key="key">
          <div class="primeira-secao">
            <div class="primeira-secao-img">
              <img :src="IconBarco" style="filter: brightness(0.5)" />
              <label class="primeira-secao-text"
                >{{ viagem.embarcacao_nome.toUpperCase() }}
              </label>
            </div>
            <p class="text-field">
              Acomodação: {{ capitalizeFirstLetter(viagem.acomodacao_nome) }}
            </p>
            <p class="text-field">Bagagem: 10kg (máx)</p>
            <!-- <label class="text-field" style="font-size: 12px">Partida</label>
            <div class="primeira-secao-img">
              <img :src="IconCalendar" />
              <label class="primeira-secao-text">{{
                formatData(viagem.dt_saida)
              }}</label>
              <img :src="IconClock" style="margin-left: 20px" />
              <label class="primeira-secao-text">{{
                formataHora(viagem.dt_saida.split(" ").splice(-1)[0])
              }}</label>
            </div> -->
          </div>
          <div class="segunda-secao">
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                justify-content: space-between;
              "
            >
              <p class="text-title">
                {{ capitalizeFirstLetter(viagem.cidade_origem) }}
              </p>

              <p style="word-wrap: nowrap">
                {{
                  formatData(viagem.dt_saida) +
                    " - " +
                    formataHora(viagem.dt_saida.split(" ").splice(-1)[0])
                }}h
              </p>
              <p>Saída</p>
            </div>
            <b-icon
              width="2rem"
              height="2rem"
              icon="arrow-right"
              style="color: #626262; margin: 0rem 1rem"
            ></b-icon>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                justify-content: space-between;
              "
            >
              <p class="text-title">
                {{ capitalizeFirstLetter(viagem.cidade_destino) }}
              </p>
              <p>
                {{
                  formatData(viagem.dt_chegada) +
                    " - " +
                    formataHora(viagem.dt_chegada.split(" ").splice(-1)[0])
                }}h
              </p>
              <p>Chegada</p>
            </div>
          </div>
          <div class="terceira-secao">
            <p style="color: #626262">Valor por Adulto</p>
            <h4 class="text-field-price">
              R${{ viagem.valor_transporte + viagem.valor_alimentacao }},00
            </h4>
            <p>(Alimentação + Transporte)</p>
          </div>
          <div class="quarta-secao">
            <button
              @click="comprar({ key: key, id: viagem.id })"
              class="button-comprar"
            >
              <span>Selecionar</span>
            </button>
          </div>
        </div>
      </div>
      <!-- <p class="text-bottom">
        As facilidades de um porto mais próximo de você.
      </p> -->
      <div class="content-header">
        <p class="subtitle">
          Ainda não se decidiu? Veja as viagens mais próximas disponíveis!
        </p>

        <div
          class="items-wrapper"
          :hidden="this.dadosPrincipais.length > 0 ? false : true"
        >
          <h2 class="titulo-slider">
            <span style="font-weight: 550">Manaus - Tabatinga</span>, subindo o
            Rio Solimões.
          </h2>
          <!-- <button class="prev" @click="moveSlide()">&#60;</button>
          <button class="next" @click="moveSlide()">&#62;</button> -->
          <div class="items">
            <div
              style="max-width: 280px; min-width: 280px"
              class="item"
              v-for="(item, index) in this.dadosPrincipais"
              :key="index"
            >
              <div class="item-image">
                <img :src="item.imagem_url" style="width: 100%;" />
              </div>
              <div class="item-info" style="height: 240px;">
                <div class="item-embarcacao">
                  <span>EMBARCAÇÃO</span>
                  <p style="white-space: wrap">
                    {{ item.embarcacao_nome.toUpperCase() }}
                  </p>
                </div>
                <div class="item-data-saida">
                  <span>DATA DE PARTIDA</span>
                  <p>{{ formatData(item.dt_saida) }}</p>
                </div>
                <div class="item-valor">
                  <span>VALOR</span>
                  <p id="item-valor">
                    R$ {{ item.valor_transporte + item.valor_alimentacao }}
                  </p>
                </div>
                <button
                  class="btn-mais-detalhes"
                  @click="comprar({ key: index, id: item.id })"
                >
                  VER MAIS
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="items-wrapper"
          :hidden="this.dadosPrincipais2.length > 0 ? false : true"
        >
          <h2 class="titulo-slider">
            <span style="font-weight: 550">Tabatinga - Manaus</span>, descendo o
            Rio Solimões.
          </h2>
          <div class="items">
            <div
              style="max-width: 280px; min-width: 280px"
              class="item"
              v-for="(item, index) in this.dadosPrincipais2"
              :key="index"
            >
              <div class="item-image">
                <img :src="item.imagem_url" style="width: 100%;" />
              </div>
              <div class="item-info" style="height: 240px;">
                <div class="item-embarcacao">
                  <span>EMBARCAÇÃO</span>
                  <p style="white-space: wrap">
                    {{ item.embarcacao_nome.toUpperCase() }}
                  </p>
                </div>
                <div class="item-data-saida">
                  <span>DATA DE PARTIDA</span>
                  <p>{{ formatData(item.dt_saida) }}</p>
                </div>
                <div class="item-valor">
                  <span>VALOR</span>
                  <p id="item-valor">
                    R$ {{ item.valor_transporte + item.valor_alimentacao }}
                  </p>
                </div>
                <button
                  class="btn-mais-detalhes"
                  @click="comprar({ key: index, id: item.id })"
                >
                  VER MAIS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StarRating from "vue-star-rating";
import config from "@/config";
import Multiselect from "vue-multiselect";

import moment from "moment";
moment.locale("pt-BR");

import {
  Facebook,
  Twitter,
  Linkedin,
  Pinterest,
  Reddit,
  Telegram,
  WhatsApp,
  Email,
  Google
} from "vue-socialmedia-share";
import api from "../api";
import { mdbAutocomplete } from "mdbvue";

import Caminho from "@/components/compras/Caminho.vue";

// Icons
import IconStar from "@/assets/icons/star.svg";
import IconShare from "@/assets/icons/share.svg";
// import IconCart from '@/assets/icons/shoppingcart.svg';
import IconMapPlace from "@/assets/icons/map_place.svg";
import IconMapMarked from "@/assets/icons/icon_destiny.svg";
import IconGPSArrow from "@/assets/icons/gps_arrow.svg";
import IconPin from "@/assets/icons/icon_pin.svg";
import PlanoFundo from "@/assets/plano_fundo.png";
import IconBarco from "@/assets/icons/icon_barco.svg";
import IconCalendar from "@/assets/icons/icon_calendar.svg";
import IconClock from "@/assets/icons/icon_clock.svg";
import IconCart from "@/assets/icons/icon_shopping_cart.svg";
import IconArrow from "@/assets/right-arrow.png";

import Footer from "@/components/Footer";

// import IconWpp from "@/assets/icons/iconsWhatsapp.svg";
export default {
  components: {
    StarRating,
    Multiselect,
    "mdb-autocomplete": mdbAutocomplete,
    Facebook,
    Twitter,
    Linkedin,
    Pinterest,
    Reddit,
    Telegram,
    WhatsApp,
    Email,
    Google,
    Caminho,
    Footer
  },
  data() {
    return {
      lang: "pt-br",
      rating: null,
      flagTitle: false,
      indisponivel: false,
      flagCard: true,
      flagLoading: false,
      dialog: false,
      message: null,
      options: {
        color: "primary",
        width: 290,
        zIndex: 200
      },
      snackbarVisible: false,
      snackbar: {
        enabled: false,
        color: "success",
        mode: "multi-line",
        timeout: 4000,
        message: "Success!",
        callback: null,
        location: "bottom",
        close: {
          text: "Close",
          color: ""
        }
      },
      datas: [],
      isValidToBeOpened: false,
      date: null,
      dateCalendar: [],
      attrs: [
        {
          dadosData: [],
          key: "today",
          highlight: "green",
          dates: []
        }
      ],
      disabledDates: ["2019-11-28", "2019-11-24", "2019-11-21"],
      data_maxvolta: "",
      data_ida2: "",
      data_ida: new Date(),
      data_hoje: new Date(),
      data_amanha: new Date(),
      currentDateISO: new Date().toISOString(),
      // data_amanha: data_hoje.setDate(data_hoje.getDate() + 1),
      data_seguinte: new Date(),
      data_volta: new Date(),
      data_volta2: "",
      origem2: "",
      destino2: "",
      time2: new Date(),
      time3: new Date(),
      country: "",
      origem: "",
      destino: "",
      Embarcacoes: [],
      selectedViagem: "",
      api: api,
      viagensOrigem: [],
      viagensDestino: [],
      viagensDestinoFiltered: [],
      dadosPrincipais: [],
      dadosPrincipais2: [],
      dadosViagem: [],
      agencias: [],
      temporario_votar: " ",

      // Icons
      PlanoFundo,
      IconStar,
      IconCart,
      IconShare,
      IconMapPlace,
      IconMapMarked,
      IconGPSArrow,
      IconPin,
      IconBarco,
      IconCalendar,
      IconClock,
      IconCart,
      IconArrow
      // IconWpp
    };
  },
  DatePickerFormat: "DD/MM/YYYY",
  watch: {
    data_ida2: function(val) {
      if (val) {
        this.setMaxDate();
      }
    }
  },
  computed: {},
  methods: {
    updateDestinos(originSelected) {
      var newDestinies = [];

      for (let i = 0; i < this.viagensDestino.length; i++) {
        if (this.viagensDestino[i].nome != originSelected.nome) {
          newDestinies.push(this.viagensDestino[i]);
        }
      }

      this.viagensDestinoFiltered = newDestinies;
      if (this.destino.nome == originSelected.nome) {
        this.destino = "";
      }
    },
    beforeOpen(event) {
      this.temporario_votar = event.params.param_voto;
    },
    showModal(myModal, votarEm = false) {
      if (votarEm) {
        this.$modal.show(myModal, { param_voto: votarEm });
      } else {
        this.$modal.show(myModal);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    openWpp() {
      window.open("https://api.whatsapp.com/send?phone=+559288552157");
    },
    novaPesquisa() {
      this.flagTitle = false;
      this.dadosViagem = [];
      this.date = null;
      this.isValidToBeOpened = false;
    },
    formataHora(horario) {
      const h = horario.substring(0, 2);
      const m = horario.substring(3, 5);
      var hora = `${h}:${m}`;
      return hora;
    },
    votar(embarcacaoId) {
      this.$modal.hide("modal-aval");
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.session.token}`
        }
      };
      const body = {
        avaliacao: this.rating
      };
      this.axios
        .post(`${api}/embarcacoes/${embarcacaoId}/avaliacao`, body, config)
        .then(response => {});
    },
    notBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    handleInput(value) {
      this.country = value;
    },
    buscarEmbarcacao() {
      var vm = this;
      this.flagCard = false;
      this.flagLoading = false;
      this.flagTitle = false;
      this.indisponivel = false;
      this.dadosViagem = [];
      vm.datas = [];
      this.axios
        .get(
          `${api}/viagens/busca/?cidade_origem=${
            this.origem.nome
          }&cidade_destino=${this.destino.nome}&dt_saida_inicial=${moment(
            this.data_ida2
          ).format("YYYY-MM-DD")}&dt_saida_final=${moment(
            this.data_volta2
          ).format("YYYY-MM-DD")}`
        )
        .then(response => {
          var aux;
          var data_1;
          var count = 0;
          if (response.data.length == 0) {
            this.indisponivel = true;
          }
          response.data.map(element => {
            this.dadosViagem.push(element);
          });
        });

      this.dadosViagem.forEach(element => {
        this.datas.push(moment(element.dt_saida).format("L"));
      });
      this.flagCard = true;
      this.flagTitle = true;
      setTimeout(function() {
        var height = $("html, body").height();
        $("html, body").animate({ scrollTop: "400px" }, "speed");
      }, 620);
      this.flagLoading = true;
    },
    toMap() {
      this.$router.push("/Mapa");
    },
    validarDados() {
      if (
        !this.origem ||
        this.origem == null ||
        !this.destino ||
        this.destino == null ||
        !this.data_ida2 ||
        this.data_ida2 == null ||
        !this.data_volta2 ||
        this.data_volta2 == null
      ) {
        this.$notify({
          message: "Preencha todos os campos",
          type: "danger",
          timeout: 2000,
          horizontalAlign: "center",
          verticalAlign: "top",
          showClose: false,
          closeOnClick: false
        });
      } else this.buscarEmbarcacao();
    },

    setMaxDate: function() {
      let string_date = this.data_ida.toLocaleDateString();
      const dia = string_date.substring(0, 2);
      const mes = string_date.substring(5, 3);
      const ano = string_date.substring(6, 10);
      var data_ida2 = new Date(`${this.data_ida2}`);
      this.data_volta.setDate(this.data_ida2.getDate() + 1);
      this.data_maxvolta = new Date(`${ano}-${mes}-${dia}`);
      this.data_maxvolta.setMonth(this.data_maxvolta.getMonth() + 1);
    },
    formatData(data) {
      // 2020-01-29 12:00:00
      if (data) {
        const ano = data.substring(0, 4);
        const mes = data.substring(5, 7);
        const dia = data.substring(8, 10);
        const hora = data.substring(12, 21);

        data = `${dia}/${mes}/${ano}`;
      }
      return data;
    },
    formatoDataBrToEn(date) {
      if (!date || date == null) {
        return;
      }
      let string_date = date.toLocaleDateString();
      const dia = string_date.substring(0, 2);
      const mes = string_date.substring(5, 3);
      const ano = string_date.substring(6, 10);

      return `${ano}-${mes}-${dia}`;
    },
    formattedDisplay(result) {
      return result.nome;
    },
    voltar(rota) {
      this.$router.push(`/${rota}`);
    },
    comprar(key) {
      if (this.$store.state.session.auth != true) {
        this.$router.push("/login");

        return;
      }
      var dados = {
        id: key.id,
        key: key.key
      };
      this.$store.dispatch("viagem", dados);
      this.$router.push({
        name: "purchase",
        params: { id: this.$route.params.id, key: key }
      });
    },

    // Carregar cards da lista/carrossel
    carregarEmbarcacao() {
      this.time2.setDate(this.time2.getDate() + 1);
      this.time3.setDate(this.time3.getDate() + 20);

      var origem = "Manaus";
      var destino = "Tabatinga";

      this.axios
        .get(
          `${api}/viagens/busca/?cidade_origem=${origem}&cidade_destino=${destino}&dt_saida_inicial=${moment(
            this.time2
          ).format("YYYY-MM-DD")}&dt_saida_final=${moment(this.time3).format(
            "YYYY-MM-DD"
          )}`
        )
        .then(response => {
          response.data.map(element => {
            this.dadosPrincipais.push(element);
          });
        });
      this.dadosPrincipais = this.dadosPrincipais.slice(0, 9);

      origem = "Tabatinga";
      destino = "Manaus";

      this.axios
        .get(
          `${api}/viagens/busca/?cidade_origem=${origem}&cidade_destino=${destino}&dt_saida_inicial=${moment(
            this.time2
          ).format("YYYY-MM-DD")}&dt_saida_final=${moment(this.time3).format(
            "YYYY-MM-DD"
          )}`
        )
        .then(response => {
          response.data.map(element => {
            this.dadosPrincipais2.push(element);
          });
        });
    },
    goTo(rota) {
      let routeData = this.$router.resolve({ name: rota });
      window.open(routeData.href, "_top");
    }
  },
  mounted() {
    var vm = this;

    this.$store.dispatch("deleteValorTotal");
    this.$store.dispatch("pop_passenger");
    this.$store.dispatch("pop_purchase");
    this.axios.get(`${api}/cidades?tipo=origem`).then(response => {
      response.data.map(res => {
        this.viagensOrigem.push({ id: res.id, nome: res.nome });
      });
    });
    this.axios.get(`${api}/cidades?tipo=destino`).then(response => {
      response.data.map(res => {
        this.viagensDestino.push({ id: res.id, nome: res.nome });
      });
      this.viagensDestinoFiltered = this.viagensDestino;
    });
    this.carregarEmbarcacao();
    if (this.$route.params.id != undefined) {
      var dados = {
        id: this.$route.params.id
      };
      this.$store.dispatch("viagem", dados);
      const config2 = {
        headers: {
          Authorization: `Bearer ${this.$store.state.session.token}`
        }
      };

      this.axios
        .get(`${api}/viagens/${this.$store.state.dadosViagem.id}`, config2)
        .then(response => {
          this.date = moment(response.data.dt_saida).format("L");
          this.dadosViagem.push(response.data);
          response.data.agencias.map(element => {
            this.agencias.push(element);
          });
        })
        .catch(err => {
          if (err.response.data.message == "Auth failed")
            this.$store.dispatch("logout");

          this.$router.push("/login");
        });
    }

    // document.querySelector(".items").addEventListener("wheel", event => {

    //   if (event.deltaY > 0) {
    //     event.target.scrollBy(300, 0);
    //   } else {
    //     event.target.scrollBy(-300, 0);
    //   }
    // });

    const slider = document.getElementsByClassName("items")[0];
    const slider2 = document.getElementsByClassName("items")[1];
    let mouseDown = false,
      mouseDown2 = false;
    let startX, scrollLeft;

    let startDragging = function(e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let startDragging2 = function(e) {
      mouseDown2 = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider2.scrollLeft;
    };
    let stopDragging = function(event) {
      mouseDown = false;
      mouseDown2 = false;
    };

    slider.addEventListener("mousemove", e => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    slider2.addEventListener("mousemove", e => {
      e.preventDefault();
      if (!mouseDown2) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider2.scrollLeft = scrollLeft - scroll;
    });

    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);

    slider2.addEventListener("mousedown", startDragging2, false);
    slider2.addEventListener("mouseup", stopDragging, false);
    slider2.addEventListener("mouseleave", stopDragging, false);
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "OpenSans-Bold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf");
}
.segunda-secao-hidden {
  display: none;
}
.content-header {
  .titulo-slider {
    margin-left: 2rem;
    margin-top: 4rem;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    color: #626262;
  }
  .title {
    font-family: "Roboto", sans-serif;
    font-size: 2rem;
  }
  .subtitle {
    font-family: "OpenSans-Regular", sans-serif;
    font-size: 1.2rem;
    margin-top: 2rem;
  }
  .title,
  .subtitle {
    text-align: center;
    color: #626262;
  }
}

.items-wrapper {
  width: 100%;
  margin-bottom: 5rem;
  position: relative;
  max-width: 100vw;
  overflow-x: auto !important;
}

.items:hover {
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.items {
  margin: 0 2rem;
  padding-bottom: 1.5rem;
  display: flex;
  overflow-x: hidden;

  cursor: grab;

  /* scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; */

  /* scroll-behavior: smooth; */
}

.prev,
.next {
  position: absolute;
  border-radius: 50%;
  border: none;
  top: 50%;
}

.prev {
  left: 1rem;
}

.next {
  right: 1rem;
}

.item + .item {
  margin-left: 5rem;
}

.item {
  flex: none;
  /* pointer-events: none; */
  margin: 2rem 0;
  /* scroll-snap-align: start; */
  cursor: default;
  .item-image {
    background-color: #009aa0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 280px;
      height: 208px;
      padding-bottom: 5px;
    }
  }
  &:hover {
    pointer-events: visiblePainted !important;
    transform: translate(0px, -6px);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    .item-info {
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
      transition: box-shadow 0.3 ease-in;
    }
  }
}
.item-info {
  padding: 0.8rem;
  margin-top: 0rem;
  background-color: #ffffff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 4px 2px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  .item-embarcacao {
    margin: 0.5rem 0;
  }

  .item-data-saida {
    margin: 1.2rem 0;
  }

  .item-valor {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  span,
  p {
    font-family: Roboto, sans-serif;
    color: #2a3550;
  }
  span {
    font-weight: 400;
    font-size: 12px;
  }
  p {
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
  }
  #item-valor {
    font-size: 36px;
  }

  .btn-mais-detalhes {
    position: absolute;
    left: 50%;
    // right: 50%;
    transform: translate(-50%, 50%);
    bottom: -0.2rem;
    padding: 0.8rem 4rem;
    background-color: #009aa0;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    &:hover {
      transform: translate(-50%, 51%);
      box-shadow: 0px 1px 10px 0px #00000011;
      transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    }

    &:active {
      outline: none;
      transform: translate(-50%, 46%);
      transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    }
  }
}
.text-bottom {
  color: white;
  font-family: "OpenSans-SemiBold", sans-serif;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1em;
  display: none;
}

.template-placeholder {
  display: flex;
  flex-direction: column;
}

.central-header {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1;
  margin-bottom: 2rem;
  margin-top: 8rem;
}
.text-field-price {
  font-size: 35px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #626262;
  margin: 4px 0;
}
.text-field-id {
  font-family: "OpenSans-Regular", sans-serif;
  margin-bottom: 5px;
}
.text-field {
  margin-left: 32px;
  font-size: 14px;
  font-weight: none;
  margin-top: 5px;
  margin-bottom: 5px;
}
.titulo {
  font-family: "Roboto", sans-serif;
  color: white;
  text-align: center;
  /* font-weight: normal; */
  font-size: 2rem;
  // font-display: swap;
  margin-bottom: 0.5rem;
}

.subtitulo {
  font-family: "Roboto", sans-serif;
  color: white;
  text-align: center;
  font-size: 2rem;
  padding: 5px 0px 5px 0px;
}
.plano-fundo {
  // margin-top: 5vh;
  width: 100%;
  height: 375px;
  // z-index: 1;
  background-color: #2a3550;
  margin: 0;
  padding: 0;
  border-bottom: 8px solid #009aa0;
  position: absolute;
}
.searches-div {
  position: relative;
  font-family: sans-serif;
  width: 100%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  .search-inputs {
    display: flex;
    width: 100%;
    flex-direction: row;
    position: relative;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    .search-input {
      display: inline-block;
      width: 100%;
      margin: 0 10px;
      @media only screen and (max-width: 600px) {
        margin: 0 auto;
      }
    }
  }
}
.search-card {
  display: flex;
  gap: 2rem;
  position: relative;
  /* justify-content: space-between; */
  background-color: #313f61;
  width: 46rem;
  border-radius: 0.4rem;
  margin: 0 auto;
  padding: 1.2rem;
  z-index: 1;
  // margin: 14px auto;
  // margin-bottom: 10px;
  margin-top: 14vh;

  // @media only screen and (max-width: 1100px) {
  //   margin-bottom: 12px;
  //   width: 60%;
  // }
  .container-1 {
    display: flex;
    flex-direction: column;
  }
  label {
    font-size: 20px;
    /* margin: 2rem 0rem; */
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: white;
  }
  /* .section-1,
  .section-2 {
    width: 45%;
    font-family: "OpenSans-Regular", sans-serif;
  } */
  .leftSide {
    display: flex;
    flex-direction: column;
    width: 40%;
  }

  #content-datepickers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    .input-date {
      font-style: italic;
      width: 140px !important;
    }
  }

  .rightSide {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  * {
    .multiselects {
      margin: 1rem 0rem;
      font-family: Roboto, sans-serif !important;
      /* font-style: italic; */
      font-size: 16px;
      line-height: 18px;
      color: #8f949d;
    }
  }
  .multiselect__placeholder {
    font-style: italic;
    color: red !important;
  }
  .searches {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;

    .search {
      width: 70%;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 0.7rem;
      @media only screen and (max-width: 450px) {
        width: 100%;
      }
    }
  }
}

.label_icon {
  position: absolute;
  right: 6px;
  top: 10px;
  background-color: white;
}

.field-label {
  margin: 0 auto;
  font-family: "OpenSans-Bold", sans-serif;
  font-weight: 200;
  font-size: 25px;
  color: #0000008a;
  // position: absolute;
}
.label-icon {
  height: 1.2rem;
  position: relative;
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-left: 0.1rem;
  filter: invert(15%) sepia(6%) saturate(6011%) hue-rotate(176deg)
    brightness(91%) contrast(90%);
}

.text-button {
  font-family: "OpenSans-SemiBold", sans-serif !important;
  font-size: 1em !important;
  color: white !important;
}
.search-button {
  position: relative;
  padding: 0.8rem 2rem;
  margin-right: 0.4rem;
  margin-bottom: 1rem;
  background-color: #009aa0;
  font-family: Roboto, sans-serif;
  font-weight: 600 !important;
  border: 0;
  border-radius: 0.25rem;
  font-family: sans-serif;
  text-align: center;
  cursor: pointer;
  align-self: flex-end;
  color: white;
}
.entire-div {
  padding-top: 8rem;
  z-index: 0;
  background-color: #f0f2f5;
}
// @media only screen and (min-width: 320px) {
//   .encontrado {
//     width: 90%;
//   }
// }
.aviso {
  display: block;
  text-align: center;
  padding-top: 0.4rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  color: #404040;
  border-top: 1px dashed #cccccc;
  margin-top: 1rem;
}

.div-calendario {
  padding: 1rem;
  border-radius: 0.5rem;
  width: 40%;
  background-color: white;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 1100px) {
    width: 60%;
  }
  @media only screen and (max-width: 450px) {
    width: 80%;
  }

  .calendario-title {
    text-align: center;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    padding-bottom: 1rem;
    border-bottom: 2px dashed #cccccc;
    color: #344675;
  }

  .calendario {
    margin: 1rem auto;
    @media only screen and (max-width: 450px) {
      transform: scale(0.8);
      margin-bottom: 0;
    }
  }

  .div-do-botao-calendario {
    width: 50%;
    margin: 0 auto;
    @media only screen and (max-width: 450px) {
      width: 60%;
    }

    .calendario-button {
      position: relative;
      color: white;

      padding: 0.5rem 10%;

      background-image: linear-gradient(
        to bottom left,
        #344675,
        #263148,
        #344675
      );
      background-position-x: 100%;
      background-position-y: 0px;
      background-size: 210% 210%;
      background-attachment: initial;
      background-origin: initial;
      background-clip: initial;
      background-color: #344675;

      border: 0;
      border-radius: 0.25rem;
      font-family: sans-serif;
      font-size: 100%;

      text-align: center;
      width: 100%;
      margin: 1rem auto;

      cursor: pointer;
    }
  }
}

.titulo-encontrados {
  margin: 15px auto;
  text-align: center;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  // font-weight: 600;
  color: #626262;
  background-color: transparent;
  padding: 1rem 0;
  @media only screen and (max-width: 450px) {
    font-size: 1.5rem;
  }
}
.titulo-encontrados2 {
  // margin: 15px auto;
  text-align: center;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  // font-weight: 600;
  color: #626262;
  background-color: transparent;
  padding: 1rem 0;
  @media only screen and (max-width: 450px) {
    font-size: 1.5rem;
  }
}

.div-encontrados {
  width: 100%;
}

.primeira-secao {
  display: flex;
  color: #626262;
  flex-direction: column;
  align-content: center;
  align-self: center;
}

.primeira-secao-img {
  display: flex;
  align-content: center;
  align-items: center;
}
.primeira-secao-text {
  margin-left: 8px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 20px;
  max-width: 200px;
}

.segunda-secao {
  height: 4.2rem;
  display: flex;
  align-content: center;
  align-self: flex-start;
  align-items: center;
  color: #626262;
  p {
  }
}

.text-title {
  color: #009aa0;
  padding: 5px 8px;
  border-radius: 22px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.terceira-secao {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-self: center;
  align-items: flex-start;
  color: #626262;
  font-family: "Roboto";
}
.segunda-secao-img {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.encontrado {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: 2px auto;
  margin-bottom: 2.5rem;
  padding: 1.5rem 1rem;
  overflow: hidden;
  border-radius: 0.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  @media only screen and (max-width: 900px) {
    width: 95%;
  }
}
.quarta-secao {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  justify-content: center;
  width: 20%;
  color: #626262;
}

.button-comprar {
  position: relative;
  color: white;
  font-family: "OpenSans-Bold", sans-serif;
  font-weight: 400;
  padding: 0.8rem 0;
  width: 70%;
  background: #313f61;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 0;
  border-radius: 0.5rem;
  font-size: 80%;

  text-align: center;

  margin: 0.3rem auto;

  cursor: pointer;
}

button:hover {
  transform: translate(0px, -1px);
  box-shadow: 0px 1px 10px 0px #00000011;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:active {
  outline: none;
  transform: translate(0px, 1px);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

button:focus {
  outline: none;
}

.div-nova-pesquisa {
  width: 100%;
  margin: 1rem auto;
  text-align: center;

  .button-nova-pesquisa {
    text-align: center;
    margin: 0 auto;
    width: 50%;
    background-color: #344675;
    position: relative;
    display: block;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    padding: 0.4rem 0;
    width: 50%;
    @media only screen and (max-width: 950px) {
      width: 80%;
    }
    @media only screen and (max-width: 450px) {
      width: 90%;
    }

    border: 0;
    border-radius: 0.5rem;
    font-size: 80%;

    text-align: center;

    margin: 0.3rem auto;

    cursor: pointer;
  }
}

.icon-pesquisar {
  padding-right: 0.6rem;
}

.icon-avaliar {
  filter: invert(100%) sepia(0%) saturate(7490%) hue-rotate(320deg)
    brightness(101%) contrast(103%);
  height: 1rem;
  margin-right: 0.2rem;
}

.icon-compartilhar {
  filter: invert(100%) sepia(0%) saturate(7490%) hue-rotate(320deg)
    brightness(101%) contrast(103%);
  height: 1rem;
  margin-right: 0.2rem;
}

.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.3;
  display: none;
}

.hiddenable {
  @media only screen and (max-width: 500px) {
    display: none;
  }
}

.modal-aval {
  .header {
    background-color: #344675;
    text-align: center;
    font-size: 2rem;
    padding: 0.7rem 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: white;
  }

  .body {
    padding: 1rem;

    .aval-modal-button {
      background-color: #344675;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      padding: 0.3rem 0.6rem;
      border: 0;
      border-radius: 0.5rem;
      font-size: 100%;
      text-align: center;
      margin-bottom: 0;
      cursor: pointer;
      color: white;
      vertical-align: middle;
      margin: 0.6rem auto;
      width: 100%;
    }
  }
}

.my-stars {
  margin: auto auto;
  justify-content: space-around;
}

.modal-share {
  .header {
    background-color: #344675;
    text-align: center;
    font-size: 2rem;
    padding: 0.7rem 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: white;
  }

  .body {
    padding: 1rem;

    .compartilhar-list {
      list-style-type: none;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      li {
        padding: 0 0.2rem;
      }

      li:hover {
        opacity: 0.8;
        cursor: pointer;
        transform: translate(0px, 1px);
      }

      li::before {
        display: none;
      }
    }
  }
}
.vc-container {
  margin: 0 auto;
}
@media only screen and (max-width: 740px) {
  .encontrado {
    .primeira-secao {
      font-size: 0.8em;
      img {
        width: 16px;
      }
    }
    .segunda-secao {
      display: none;
    }
    .terceira-secao {
      font-size: 0.8em;
      justify-content: space-between;
      align-self: unset;
      align-content: unset;
      align-items: unset;
      .segunda-secao-hidden {
        display: initial;
        .text-type-acomodacao {
          margin: 0px;
          padding: 0px;
          border-radius: 0px;
        }
      }
    }
    .quarta-secao {
      justify-content: space-between;
      font-size: 0.8em;
      .text-field-price {
        font-size: 1.4em;
      }
      img {
        width: 16px;
      }
    }
  }
}
@media only screen and (max-width: 380px) {
  .encontrado {
    font-size: 85%;
  }
}

@media only screen and (max-width: 425px) {
  .search-card {
    flex-direction: column;
    gap: 1rem;
    padding: 1.8rem;
    max-width: 80%;

    width: 70vw !important;

    .leftSide {
      width: 100%;
    }

    .search-button {
      margin-top: 20px;
      align-self: center;
      margin-bottom: 0;
    }
  }

  .item {
    margin-right: -50px;
  }

  h2.titulo-slider {
    width: 90%;
    word-wrap: break-word;
  }
}

@media only screen and (max-width: 900px) {
  .titulo-encontrados2 {
    font-size: 1em;
  }
  .text-bottom {
    display: block;
  }
  /* .IconWpp {
    display: none;
  } */
  .central-header {
    word-wrap: break-word;
  }
  .search-card {
    width: fit-content;
    font-size: 0.7em;
  }
  .items-wrapper {
    width: 100vh;
  }
  .content-header {
    width: fit-content;
    .title {
      font-size: 1.4rem;
    }
    .subtitle {
      font-size: 1.2rem;
    }
  }
  .entire-div {
    min-width: 320px;
  }
  .items {
    overflow: auto;
  }
  .titulo {
    font-size: 1.4em;
  }
  .subtitulo {
    font-size: 1.2rem;
  }
  .container-1 {
    display: flex;
    flex-direction: column;
    .section-2 {
      margin-left: 0rem !important;
    }
    .section-1,
    .section-2 {
      width: 100%;
    }
  }
  .section-3 {
    .container-datas {
      flex-direction: column;
      .input-date {
        width: 93% !important;
      }
      .responsive {
        margin-left: 0rem !important;
      }
    }
  }
}
</style>
